@import url("https://db.onlinewebfonts.com/c/7bd7f049ab12f7efc10d48e5fa57a618?family=Mija + W03 + Bold");
@import url("https://fonts.googleapis.com/css2?family=Funnel+Display:wght@300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Preahvihear&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.movie-schedule-container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centers the entire column */
  align-items: center;
}

.selector-row {
  display: flex;
  align-items: center; /* Vertically centers the items */
  justify-content: space-between; /* Places items at each end of the row */
  width: 100%;
  max-width: 650px;
  border-bottom: 1px solid #ccc; /* Optional: full-width bottom border */
  padding-top: 20px;
}

.date-selector {
  gap: 5px;
}

.city-selector,
.date-selector {
  display: flex;
  align-items: center;
  font-family: "Barlow";
  margin: 0; /* Remove default margins */
  padding: 0; /* Remove default padding */
}

.city-selector {
  font-weight: 800;
}

#date-select {
  appearance: none; /* Remove default arrow */
  -webkit-appearance: none; /* For Safari */
  -moz-appearance: none; /* For Firefox */
  background: none;
  border: none;
  font-family: "Barlow";
  font-weight: 800;
  font-size: 1rem;
  color: #333;
  cursor: pointer;
  width: auto; /* Allow the width to adjust based on content */

  appearance: none; /* Remove default styling */
  background: none;
  border: none;
  text-decoration: underline; /* Underline the text */
  cursor: pointer; /* Change the cursor to indicate it's clickable */
}

/* Style the select element to look like plain text */
.city-selector select {
  appearance: none;
  -webkit-appearance: none; /* For Safari */
  -moz-appearance: none; /* For Firefox */
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font-size: 1rem; /* Adjust to match your text size */
  font-family: inherit;
  color: inherit;
  cursor: pointer; /* Indicates it's clickable */
  /* Remove the dropdown arrow in IE10+ */
  /* For IE10+ */
  &::-ms-expand {
    display: none;
  }
}

/* Style the select element to look like plain text */
.date-selector select {
  appearance: none;
  -webkit-appearance: none; /* For Safari */
  -moz-appearance: none; /* For Firefox */
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font-size: 1rem; /* Adjust to match your text size */
  font-family: inherit;
  color: inherit;
  cursor: pointer; /* Indicates it's clickable */
  /* Remove the dropdown arrow in IE10+ */
  /* For IE10+ */
  &::-ms-expand {
    display: none;
  }
}

/* Remove focus outline */
.date-selector select:focus {
  outline: none;
}

/* Style the option elements if necessary */
.date-selector select option {
  color: #2c3e50; /* Adjust text color */
}

.app-header {
  display: flex;
  justify-content: center;
  background-color: #ff7f50;
  padding-bottom: 5px;
}

.app-logo {
  width: 180px; /* Adjust size as needed */
  height: auto; /* Maintain aspect ratio */
  margin-top: 15px;
}

body {
  background-color: #faf3e0;
  font-family: "Preahvihear";
}

.movie-schedule {
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the left */
  padding: 0;
  width: fit-content;
  padding: 20px;
}

/* If you want the first card to have a border to separate it from the dropdown */
.movie-card {
  width: 100%;
  max-width: 650px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #ddd;
  position: relative;
  overflow: hidden;
  border-top: 1px;
  margin-top: 15px;
  margin-bottom: 10px;
}

.movie-card,
.date-selector {
  padding: 0;
}

.movie-image img {
  width: 70px;
  height: 105px;
  border-radius: 2.5px;
  margin-right: 15px;
  margin-top: 5px;
}

.movie-info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.movie-title {
  line-height: 1;
  color: #ff7f50;
  padding: 0;
  margin: 0;
  vertical-align: top;
  width: 100%;
  font-family: "Funnel Display";
}

.movie-year {
  font-size: 0.75em;
  color: #aaa;
  margin-left: 3px;
  font-family: "Funnel Display";
  font-weight: 200;
}
.movie-details {
  display: flex;
  align-items: center; /* Vertically center the items */
  gap: 8px; /* Adjust spacing between elements */
  margin-top: 9px;
  margin-bottom: 0px;
  font-family: "Barlow";
  font-weight: 600;
}

.movie-rating-container {
  display: flex;
  align-items: center; /* Align the SVG and the rating text */
}

.imdb-rating-container {
  display: flex;
  align-items: center; /* Align the SVG and the rating text */
}

.rotten-svg {
  width: 15px; /* Adjust width for better fit */
}

.imdb-svg {
  width: 35px; /* Set the desired width */
  margin-left: 2px;
}

.movie-details span,
.movie-description {
  color: #2c3e50;
}

.movie-description {
  width: 100%;
  margin-top: 4px;
  font-size: 0.95em;
  line-height: 1.4;
  margin-bottom: 40px;
  font-family: "Barlow";
  font-weight: 400;
}

.cinema-icon-container {
  position: relative; /* Ensure this is set to relative */
}

.showtime-select {
  position: absolute; /* Absolute positioning */
  top: -33px; /* Adjust top to move the select above the icon */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Adjust horizontal centering */
  z-index: 10; /* Ensure it appears above other elements */
  background-color: white;
  border: 1px solid #ccc;
  padding: 5px;
  width: 65px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional shadow for better visibility */
}

.showtime-icons {
  display: flex;
  gap: 4px;
  position: absolute;
  bottom: -3px;
  right: 10px;
  padding: 0;
  z-index: 1;
}

.cinema-icon-container {
  position: relative;
  font-family: "Barlow";
}

.cinema-logo {
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: 50%;
  object-fit: cover;
}

.showtime:hover .cinema-logo {
  background-color: #00b8d9;
}

.button {
  background-color: #00b8d9;
  color: #faf3e0;
  padding: 8px 15px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.button:hover {
  background-color: #ff6b6b;
}

.tooltip {
  position: fixed; /* Align with viewport */
  transform: translate(-50%, -100%); /* Center horizontally and move above icon */
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  font-size: 0.9em;
  text-align: center;
  white-space: pre-wrap; /* Ensure newlines are respected */
}

.cinema-icon-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.more-button {
  background: none; /* Remove default button background */
  border: none; /* Remove border */
  color: inherit; /* Inherit text color from parent element */
  font-weight: bold; /* Make text bold */
  cursor: pointer; /* Change cursor to pointer on hover */
  padding-left: 4; /* Remove default padding */
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
  margin: 0; /* Remove default margin */
  font-size: inherit; /* Inherit font size from parent element */
  text-decoration: underline; /* Optional: Add underline to indicate clickable text */
}

.more-button:hover {
  text-decoration: underline; /* Optional: Keep underline on hover for consistency */
}

.movie-link {
  color: inherit;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.movie-card:first-of-type {
  margin-top: 15px; /* No extra margin at the top */
}

.movie-duration {
  margin-right: 5px;
}

.movie-genre {
  margin-left: 5px;
}
.mobile-img {
  display: none;
}

.show_times {
  display: none;
}

@media (max-width: 768px) {
  .app-header {
    position: sticky;
    top: 0;
    z-index: 999;
  }

  .movie-year {
    margin-left: 10px;
  }
  .movie-schedule {
    font-size: 14px;
  }

  .selector-row {
    position: sticky;
    top: 91px;
    z-index: 999;
    display: flex;
    justify-content: space-between;
  padding-inline: 20px;
  }
  .movie-description {
    line-height: 1.5;
    margin-bottom: 10px;
  }

  .first-movie-card {
    margin-top: 8px;
  }

  .tooltip {
    display: none;
  }

  .image-content-container {
    display: flex;
    justify-content: start;
    align-items: start;
  }
  .movie-card {
    display: flex;
    flex-direction: column;
    gap: 8px; /* Space between sections */
    flex-direction: column;
    text-align: left;
    margin-top: 4px;
  }

  .movie-card h2.movie-title {
    width: 100%;
    text-align: left;
    font-size: 1.5rem;
  }

  .movie-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 5px;
    margin: 10px 0;
  }

  .movie-details {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    font-size: 0.9rem;
    margin-top: 0px;
  }

  .showtime-icons {
    position: static;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 8px;
  }

  .show_times {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 10px;
  }

  .cinema-icon-container {
    display: flex;
    align-items: center;
    justify-content: left;
  }

  .cinema-icon-container > a {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cinema-icon-container img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    object-fit: contain;
  }
  .desktop-img {
    display: none;
  }
  .mobile-img {
    display: block;
  }
  .movie-image img {
    width: 90px;
    height: 130px;
  }
  .header-items--container {
  padding-inline: 20px;
  }
}

.coffee-button {
  width: 217px;                                /* Set width based on image size */
  height: 60px;                                /* Set height based on image size */
  cursor: pointer;
  background-image: url('https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png');
  background-size: cover;                      /* Ensure the image covers the entire area */
  background-repeat: no-repeat;                /* Prevent image tiling */
  display: inline-block;                       /* Allow width/height to take effect without flex layout */
  text-decoration: none;                       /* Remove default underline */
  border-radius: 5px;                          /* Optional: maintain rounded corners if desired */
  text-indent: -9999px;                        /* Hide the text visually */
  overflow: hidden;                            /* Prevent any accidental text from showing */
}

.contact-button {
  width: fit-content;
  height: 35px;
  cursor: pointer;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: 16px;
  text-decoration: none;
  color: white;
  padding-right: 0;
}


.nav-links {
  display: flex;
  align-items: center;
}


@media screen and (max-width: 568px) {
  .selector-row {
    gap: 10px;
  background-color: #ff7f50;
  padding-top: 0;
  padding-bottom: 20px;
  }
  .city-selector label,
  .date-selector label {
    font-size: 14px;
  }

  .date-selector a {
    font-size: 14px;
  }
  .date-label {
    display: none;
  }
  .city-text {
    display: none;
  }
  .coffee-button {
    display: none;
  }
  .coffee-button--mobile {
    display: flex !important;
  }
}

.header-items--container {
  max-width: 650px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.coffee-button--mobile {
  width: fit-content;
  height: 35px;
  background-color: #fae04b;
  cursor: pointer;
  padding: 0.5rem 1rem;
  display: none;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  border-radius: 5px;
  font-size: 12px;
}
