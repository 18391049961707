.select-container {
  position: relative;
  width: fit-content;
  user-select: none;
}

.select-trigger {
  width: fit-content;
  height: 35px;
  background-color: #faf3e0;
  cursor: pointer;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid black;
}

.select-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  color: black;
  width: 100%;
  font-weight: 400;
}

.select-arrow {
  height: 1.5rem;
  width: 1.5rem;
  transition: transform 0.2s;
}

.select-arrow.rotated {
  transform: rotate(180deg);
}

.select-dropdown {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 45px;
  min-width: 100%;
  border: 1px solid var(--background-light);
  background-color: #faf3e0;
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid black;
  z-index: 99999;
}

.select-option {
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #faf3e0;
  height: 35px;
  cursor: pointer;
  width: 100%;
  white-space: nowrap;
  border-radius: 5px;
}

.select-option:hover {
  background-color: #ff7f50;
  color: white;
  border-radius: 5px;
}

.city-selector > .select-container {
  display: none;
}

@media screen and (max-width: 568px) {
  .select-container {
    font-size: 10px;
  }
  .select-arrow {
    width: 16px;
    height: 16px;
  }
  .city-selector > .select-container {
    display: initial;
  }

  .date-options .select-trigger{
    padding-inline: 20px !important;
    border: 1px solid black !important;
  }
  .date-options .select-label {
    gap: 10px !important;
  }
  
}

.date-options .select-trigger{
  padding: 0;
  border: none;
}

.date-options .select-label {
  gap: 0;
}